exports.components = {
  "component---src-site-1-amazing-page-js": () => import("./../../../src/site1/amazing-page.js" /* webpackChunkName: "component---src-site-1-amazing-page-js" */),
  "component---src-site-1-index-js": () => import("./../../../src/site1/index.js" /* webpackChunkName: "component---src-site-1-index-js" */),
  "component---src-site-1-quiz-am-js": () => import("./../../../src/site1/quiz-am.js" /* webpackChunkName: "component---src-site-1-quiz-am-js" */),
  "component---src-site-1-quiz-b-js": () => import("./../../../src/site1/quiz-b.js" /* webpackChunkName: "component---src-site-1-quiz-b-js" */),
  "component---src-site-1-quiz-cde-js": () => import("./../../../src/site1/quiz-cde.js" /* webpackChunkName: "component---src-site-1-quiz-cde-js" */),
  "component---src-site-1-quiz-cqc-js": () => import("./../../../src/site1/quiz-cqc.js" /* webpackChunkName: "component---src-site-1-quiz-cqc-js" */),
  "component---src-site-1-quiz-revisioni-js": () => import("./../../../src/site1/quiz-revisioni.js" /* webpackChunkName: "component---src-site-1-quiz-revisioni-js" */),
  "component---src-site-1-tutorial-2-js": () => import("./../../../src/site1/tutorial-2.js" /* webpackChunkName: "component---src-site-1-tutorial-2-js" */),
  "component---src-site-1-tutorial-3-js": () => import("./../../../src/site1/tutorial-3.js" /* webpackChunkName: "component---src-site-1-tutorial-3-js" */)
}

